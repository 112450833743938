<template>
  <div class="fp-comments">
    <div class="card p-2 row mb-1">
      <h4 class="mb-2">Comments</h4>

      <!-- Loader -->
      <div
        v-if="isLoading"
        class="
          col-12
          spinner-container
          d-flex
          justify-content-center
          align-items-center
        "
      >
        <spinner></spinner>
      </div>

      <!-- Already made comment -->
      <div v-else-if="myComment.length > 0" class="col-12">
        <comment-card :comment="myComment[0]" showStatus></comment-card>
      </div>

      <!-- Write comment -->
      <div v-else class="col-12 my-2">
        <fd-form @submit="submitForm">
          <fd-textarea
            v-model="commentForm.content"
            class="col-12"
            placeholder="Write a comment"
            name="floorPlanComment"
            :rows="2"
            autoResize
            :validators="[validator.required]"
          ></fd-textarea>

          <div class="col-12 d-flex justify-content-end">
            <button class="btn main mt-1">Comment</button>
          </div>
        </fd-form>
      </div>
      <!-- <div class="col-12 mb-3 lineBottom"></div> -->
      <!-- Comments display -->
      <!-- <div class="col-12">
        <comment-card v-for="n in 6" :key="n" class="mb-3"></comment-card>
      </div> -->
    </div>
  </div>
</template>

<script>
import FloorPlanCommentModel from "@/models/floorPlanComment";
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {
    CommentCard: () =>
      import("@/components/ManageFloorPlanComments/CommentCard"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [],
  props: {
    floorPlanId: {
      type: [Number, String],
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,

      commentForm: {
        content: "",
        projectUnitFloorPlanId: ""
      },

      myComment: [],

      validator: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;
      this.commentForm.projectUnitFloorPlanId = this.floorPlanId;
      await this.getMyComment();
      this.isLoading = false;
    },
    async getMyComment() {
      try {
        let response = await this.$store.dispatch(
          "manageFloorPlanComments/getAllComments",
          {
            queries: {
              self: true,
              "projectUnitFloorPlan:id": this.floorPlanId
            }
          }
        );
        this.myComment = this._.cloneDeep(response.data);
      } catch (error) {
        throw error;
      }
    },
    async submitForm() {
      try {
        this.$store.commit("setIsLoading", true);

        let response = await this.$store.dispatch(
          "manageFloorPlanComments/createComment",
          FloorPlanCommentModel.postPayload(this.commentForm)
        );
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Your comment has been submitted successfully, now pending for admin's approval."
        });

        await this.getMyComment();

        this.$store.commit("setIsLoading", false);

        // Next #TODO: Get new comments collection
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        this.$reportError(error, "Write comment");
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.fp-comments {
  .spinner-container {
    min-height: 300px;
  }
}
</style>
